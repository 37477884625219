import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick-theme.css"
        />
        <title>HAYAFUJI | 酒田で家を建てるなら早藤工務店</title>
        {/* ogp */}
        <meta name="description" content="株式会社早藤工務店は、山形県酒田市にオフィスを構える建築工務店です。同じ設計図は二度と使わない。施主と一緒に０からプランを創り上げていきます。" />
        <meta property="og:title" content="HAYAFUJI | 酒田で家を建てるなら早藤工務店" />
        <meta property="og:description" content="株式会社早藤工務店は、山形県酒田市にオフィスを構える建築工務店です。同じ設計図は二度と使わない。施主と一緒に０からプランを創り上げていきます。" />
        <meta property="og:url" content="https://hayafuji-5e256.web.app/" />
        <meta property="og:image" content="https://hayafuji-5e256.web.app//images/ogpimage.jpg" />
        <meta name="twitter:card" content="" />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
        <script>
          {`
          (function(d)
          {
     var config = {
      kitId: 'hbd0zck',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s) 
    }
          )(document);`}
        </script>

        {/* ogp */}
      </Helmet>
    </>
  );
};

export default Head;
